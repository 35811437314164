import React from "react"
import styles from "./checkBox.module.scss"

const CheckBox = ({index, name, text, value, onChange}) => (
  <div className={styles["check-box"]}>
    <label htmlFor={`${index}_${name}`}>
      <input id={`${index}_${name}`} type='checkbox' name={name} value={value} onChange={e => onChange(e)}/>
      <div className={styles["check-box_square"]} />
      {text}
    </label>
  </div>
);

export default CheckBox