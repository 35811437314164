import React, { useState } from "react"
import getFirebase from "../../utils/firebase";
import addToMailchimp from "gatsby-plugin-mailchimp"
import Input from "../input/input"
import RadioButton from "../radioButton/radioButton"
import CheckBox from "../checkBox/checkBox"
import Button from "../button/button"
import Spinner from "../spinner/spinner"
import SocialMediaButtons from "../socialMediaButtons/soclalMediaButtons"
import styles from "./launchForm.module.scss"

import * as VALIDATIONS from '../../utils/validations';

const socialMediaItems = [
  {
    id: "fb",
    label: "Facebook",
    url: "https://facebook.com/sharer/sharer.php?u=https%3a%2f%2fevvafinanzas.com%2fcuenta-empresarial-digital%2f",
    amplitudeClickEvent: "PreReferralFacebook"
  },
  {
    id: "tw",
    label: "Twitter",
    url: "https://twitter.com/intent/tweet/?text=Te%20invito%20a%20registrarte%20en%20evva%20finanzas%20y%20abre%20una%20cuenta%20kuspit%20empresarial%20en%20donde%20podr%c3%a1s%20generar%20rendimientos%20diarios.%20reg%c3%adstrate%20ya%20en%20https%3a%2f%2fevvafinanzas.com%2fcuenta-empresarial-digital%2f",
    amplitudeClickEvent: "PreReferralTwitter"
  },
  {
    id: "email",
    label: "Email",
    url: "mailto:?subject=Te%20invito%20a%20registrarte%20en%20evva%20finanzas%20y%20abre%20una%20cuenta%20kuspit%20empresarial%20en%20donde%20podr%c3%a1s%20generar%20rendimientos%20diarios.%20reg%c3%adstrate%20ya%20en%20https%3a%2f%2fevvafinanzas.com%2fcuenta-empresarial-digital%2f",
    amplitudeClickEvent: "PreReferralEmail"
  },
  {
    id: "in",
    label: "LinkedIn",
    url: "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fevvafinanzas.com&title=Te%20invito%20a%20registrarte%20en%20evva%20finanzas%20y%20abre%20una%20cuenta%20kuspit%20empresarial%20en%20donde%20podr%c3%a1s%20generar%20rendimientos%20diarios.%20reg%c3%adstrate%20ya%20en%20https%3a%2f%2fevvafinanzas.com%2fcuenta-empresarial-digital%2f",
    amplitudeClickEvent: "PreReferralLinkedIn"
  },
  {
    id: "wa",
    label: "WhatsApp",
    url: "https://wa.me/?text=Te%20invito%20a%20registrarte%20en%20evva%20finanzas%20y%20abre%20una%20cuenta%20kuspit%20empresarial%20en%20donde%20podr%c3%a1s%20generar%20rendimientos%20diarios.%20reg%c3%adstrate%20ya%20en%20https%3a%2f%2fevvafinanzas.com%2fcuenta-empresarial-digital%2f",
    amplitudeClickEvent: "PreReferralWhatsapp"
  },
];

const content = {
  es: {
    email: "Correo Electrónico",
    name: "Nombre Completo",
    source: "Cómo nos conociste?",
    country: "Código",
    phone: "Teléfono a 10 digitos",
    company: "Empresa",
    radioGroup: [
      {
        id: "Moral",
        label: "Soy Persona Moral (S.A./S.A.P.I/C.V, etc.)",
        value: "Moral"
      },
      {
        id: "Fisica",
        label: "Soy Persona Física con Negocio o Freelancer",
        value: "Fisica"
      }
    ],
    checkBoxGroup: [
      {
        id: "1",
        label: "EVVA Cuenta Empresarial"
      },
      {
        id: "2",
        label: "Bind Cloud ERP"
      },
      {
        id: "4",
        label: "Mifiel Factura Digital"
      },
      {
        id: "8",
        label: "Asesoría Legal"
      },
      {
        id: "16",
        label: "Factura Digital"
      },
      {
        id: "32",
        label: "Punto de Venta"
      },
      {
        id: "64",
        label: "Contabilidad en línea"
      },
      {
        id: "128",
        label: "Seguro PyME"
      },
      {
        id: "256",
        label: "Factoraje Financiero"
      }
    ],
    button: "Enviar",
    formResponse: {
      success: "Tu solicitud fue enviada exitosamente",
      error: "Hubo un error al enviar tu registro, o ya estás registrado, contáctanos en info@evvafinanzas.com para poder ayudarte."
    },
    spinner: "Enviando...",
    successMsg: "<h2>¡Gracias, en un momento recibirás un correo con tu acceso!</h2><h3>Antes de irte y como recompensa a tu interés, ¡Obtén acceso gratuito a la plataforma para ti y  todos tus contactos! (proveedores, distribuidores, clientes, etc.)</h3><h4>¿Cómo? <strong>¡Solo invítalos a EVVA!</strong> a través de redes sociales</h4>",
    note: "Al continuar aceptas nuestra <a href='../PC.pdf' target='_blank' rel='noopener noreferrer'>política de privacidad</a> y <a href='../TC.pdf' target='_blank' rel='noopener noreferrer'>términos de uso.</a>"
  },
  en: {
    email: "Email address",
    name: "Full name",
    source: "How did you find us?",
    country: "Code",
    phone: "10-digits phone",
    company: "Company name",
    radioGroup: [
      {
        id: "Moral",
        label: "Limited Company (S.A./S.A.P.I/C.V, etc.)",
      },
      {
        id: "Fisica",
        label: "Sole Proprietorship and Freelancers",
      }
    ],
    checkBoxGroup: [
      {
        id: "1",
        label: "EVVA Business Account"
      },
      {
        id: "2",
        label: "Bind Cloud ERP"
      },
      {
        id: "4",
        label: "Mifiel Digital Signature"
      },
      {
        id: "8",
        label: "Legal Advice"
      },
      {
        id: "16",
        label: "Digital Invoicing"
      },
      {
        id: "32",
        label: "Point of Sale"
      },
      {
        id: "64",
        label: "Online Accounting"
      },
      {
        id: "128",
        label: "Insurance"
      },
      {
        id: "256",
        label: "Factoring"
      }
    ],
    button: "Submit",
    formResponse: {
      success: "Your request was submitted successfully",
      error: "Something went wrong. Please contact us at info@evvafinanzas.com"
    },
    spinner: "Sending...",
    successMsg: "<h2>Thanks, you're now part of the first accounts!</h2><h3>Before leaving and as a reward for your early interest, get free acess with all of your contacts! (suppliers, distributors, customers, friends, etc.)</h3><h4>How? <strong>Just invite them to EVVA!</strong> share on social media</h4>",
    note: "By continuing you accept our <a href='../PC.pdf' target='_blank' rel='noopener noreferrer'>privacy policy</a> and <a href='../TC.pdf' target='_blank' rel='noopener noreferrer'>terms of use.</a>"
  }
};

const LaunchForm = ({title, subtitle, extraFields, lang, amplitude, amplitudeClickEvent}) => {
  const [displayButton, setDisplayButton] = useState(true);
  const [displayMsg, setDisplayMsg] = useState(true);
  const [formResponse, setFormResponse] = useState({});
  const [validations, setValidations] = useState({});
  const [formData, setFormData] = useState({
    EMAIL: "",
    FNAME: "",
    SOURCE: "",
    BTYPE: "",
    UNICLICK_CREDIT: false,
    PHONE: "",
    COUNTRY: "+52",
    COMPANY: ""
  });

  const setHTMLContent = (msg) => ({__html: msg});

  const changeInputHandler = (e) => {
    const {name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if(checked) {
        setFormData({...formData, [name]: name === "UNICLICK_CREDIT" ? true : value})
      } else {
        const findItem = Object.keys(formData).find(item => item === name);

        if (findItem === "UNICLICK_CREDIT") {
          setFormData({...formData, [findItem]: false})
        } else {
          delete formData[findItem];
          setFormData(formData)
        }
      }

    } else {
      setFormData({...formData, [name]: value})
    }

  };

  const validateForm = () => {
    const validators = {
      EMAIL: [VALIDATIONS.required, VALIDATIONS.email],
      FNAME: [VALIDATIONS.required, VALIDATIONS.alphaNumericWithSpaces, VALIDATIONS.minLength(3)],
      SOURCE: [VALIDATIONS.required, VALIDATIONS.minLength(3), VALIDATIONS.maxLength(250)],
      BTYPE: [VALIDATIONS.required]
    };

    if (formData.UNICLICK_CREDIT) {
      validators.PHONE = [VALIDATIONS.required, VALIDATIONS.phoneNumber];
      validators.COMPANY = [VALIDATIONS.required, VALIDATIONS.alphaNumericWithSpaces, VALIDATIONS.minLength(3), VALIDATIONS.maxLength(250)];
    }

    return VALIDATIONS.validate(formData, validators);
  };

  const submitHandler = () => {
    const validationErrors = validateForm();
    setValidations(validationErrors);

    if (Object.keys(validationErrors).length === 0) {

      if (amplitude && amplitudeClickEvent) {
        amplitude.getInstance().logEvent(amplitudeClickEvent);

        if (formData.UNICLICK_CREDIT) {
          amplitude.getInstance().logEvent('PreSignUpUniclickCreated')
        }
      }

      setDisplayButton(false);

      const uniclickCreateLead = getFirebase().functions().httpsCallable('uniclick-createLead');

      addToMailchimp(formData.EMAIL, formData).then(data => {
        if (data.result === 'success') {

          if (formData.UNICLICK_CREDIT) {
            uniclickCreateLead(formData).then(result => {
              if(result.data.messageId) {
                setFormResponse({result: "success"});
                setDisplayButton(true);

                const t = window.setTimeout(() => {
                  setDisplayMsg(false);
                  clearTimeout(t);
                }, 3000);

              }
            })
          } else {
            setFormResponse(data);
            setDisplayButton(true);

            const t = window.setTimeout(() => {
              setDisplayMsg(false);
              clearTimeout(t);
            }, 3000);
          }

        } else {
          setFormResponse(data);
        }

      })
    }
  };

  return (
    <div>
      {formResponse.result === 'success' ? (
        <div>
          {displayMsg &&
          <div className={styles['launch-form_success']}>
            {content[lang].formResponse[formResponse.result]}
          </div>
          }
          <div className={styles["launch-form"]}>
            <div className={styles["launch-form_title"]} dangerouslySetInnerHTML={setHTMLContent(content[lang].successMsg)}/>
            <div className={styles["launch-form_social_media"]}>
              <SocialMediaButtons items={socialMediaItems} amplitude={amplitude}/>
            </div>
          </div>
        </div>
      ):(
        <div>
          {formResponse.result === 'error' &&
          <div className={styles['launch-form_error']}>
            {content[lang].formResponse[formResponse.result]}
          </div>
          }
          <div className={styles["launch-form"]}>
            {(title || subtitle) &&
            <div className={styles["launch-form_title"]}>
              {title && <h2>{title}</h2>}
              {subtitle && <h3>{subtitle}</h3>}
            </div>
            }
            <div className={styles["launch-form_form"]}>
              <Input name="EMAIL" value={formData.EMAIL} prompt={content[lang].email} onChange={changeInputHandler}/>
              {validations.EMAIL && <div className={styles["launch-form_validations"]}>{validations.EMAIL[lang]}</div>}
              <Input name="FNAME" value={formData.FNAME} prompt={content[lang].name} onChange={changeInputHandler}/>
              {validations.FNAME && <div className={styles["launch-form_validations"]}>{validations.FNAME[lang]}</div>}
              <Input name="SOURCE" value={formData.SOURCE} prompt={content[lang].source} onChange={changeInputHandler}/>
              {validations.SOURCE && <div className={styles["launch-form_validations"]}>{validations.SOURCE[lang]}</div>}

              {formData.UNICLICK_CREDIT &&
              <div>
                <Input name="COMPANY" value={formData.COMPANY} prompt={content[lang].company} onChange={changeInputHandler}/>
                {validations.COMPANY && <div className={styles["launch-form_validations"]}>{validations.COMPANY[lang]}</div>}
              </div>
              }
              {formData.UNICLICK_CREDIT &&
              <div className={styles["launch-form_form_field"]}>
                <span>
                  <Input name="COUNTRY" value={formData.COUNTRY} prompt={content[lang].country} onChange={changeInputHandler} disabled/>
                </span>
                <Input name="PHONE" value={formData.PHONE} prompt={content[lang].phone} onChange={changeInputHandler}/>
              </div>
              }
              {formData.UNICLICK_CREDIT && validations.PHONE && <div className={styles["launch-form_validations"]}>{validations.PHONE[lang]}</div>}

              {extraFields !== "services" &&
              <div>
                <CheckBox index={0} name="UNICLICK_CREDIT" value={formData.UNICLICK_CREDIT} text="Deseas un Crédito PyME" onChange={changeInputHandler}/>
                <div className={styles["launch-form_division"]} />
              </div>
              }

              {content[lang].radioGroup.map((type, index) => (
                  <RadioButton key={type.id} index={index} name="BTYPE" value={type.id} text={type.label} onChange={changeInputHandler}/>
              ))}
              {validations.BTYPE && <div className={styles["launch-form_validations"]}>{validations.BTYPE[lang]}</div>}
              {extraFields === "services" &&
                <div>
                  <div className={styles["launch-form_division"]}>{lang === "en" ? "Choose services you are interest:" : "Selecciona los servicios de tu interes:"}</div>
                  {content[lang].checkBoxGroup.map((type, index) => (
                    <CheckBox key={type.id} index={index} name={`group[5015][${type.id}]`} value={type.id} text={type.label} onChange={changeInputHandler}/>
                  ))}
                </div>
              }
              <div className={styles["launch-form_action"]}>
                {displayButton ? (
                <Button text={content[lang].button} onClick={() => submitHandler()}/>
                ):(
                <Spinner text={content[lang].spinner} border/>
                )}
              </div>
              <div className={styles["launch-form_note"]} dangerouslySetInnerHTML={setHTMLContent(content[lang].note)} />
            </div>
          </div>
        </div>
      )}

    </div>
  )
};

export default LaunchForm
